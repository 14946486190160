import React from 'react';
import { observer } from 'mobx-react';
import Router from 'next/router';
import { Mobile } from 'bu.components-customer-apps';

import { GeneralTrackingPages } from 'bu.general-tracking';
import type { IReactionDisposer } from 'mobx';
import Page from '../components/page';
import OnboardingPersonalDataSection from '../components/onboarding/onboarding-personal-data-section';
import OnboardingProgressBar from '../components/onboarding/onboarding-progress-bar';
import InsuranceLink from '../components/insurance-list/insurance-link';
import TrustLogos from '../components/common/trust-logos/trust-logos';

import searchDataService from './../services/search-data.service';
import searchDataUrlSyncService from '../services/search-data-url-sync.service';
import navigationService from '../services/navigation.service';
import piwikService from '../services/piwik.service';
import campaignService from '../services/campaign.service';

import storeFactory from '../factories/store.factory';

import CampaignBanner from '../components/campaign/campaign-banner';
import ConsultantInformationCarousel from '../components/consultant-carousel/consultant-information-carousel';
import OnboardingCta from '../components/onboarding/onboarding-cta';

import { SearchData } from '../types/search-data';
import { SsoUserData } from '../types/sso-user-data';
import { ExpiredSessionData } from '../types/expired-session-data';
import { Campaign } from '../types/campaign';
import { convertQueryToStringsOnlyQuery } from '../helpers/convert-query.helper';
import endOfYearBannerService from '../services/end-of-year-banner.service';
import { BuNextPageContext } from '../types/next-types';

interface Props {
  searchData: SearchData;
  ssoUserData: SsoUserData | undefined;
  expiredSessionData: ExpiredSessionData | undefined;
  campaign: Campaign;
}

@observer export default class OnboardingPersonalDataPage extends React.Component<Props> {
  searchDataStore = storeFactory.getSearchDataStore();
  ssoStore = storeFactory.getSsoStore();
  searchDataReadStore = storeFactory.getSearchDataReadStore();
  onboardingValidationStore = storeFactory.getOnboardingValidationStore();
  disposeSavePrefillingOnBpm?: IReactionDisposer;

  static async getInitialProps({ req, query }: BuNextPageContext) {
    const stringsOnlyQuery = convertQueryToStringsOnlyQuery(query);
    const [searchData, campaign] = await Promise.all([
      searchDataService.createSearchDataFromQuery(req, stringsOnlyQuery),
      campaignService.getActiveCampaign()
    ]);

    return { searchData, campaign };
  }

  constructor(props: Props) {
    super(props);

    const { searchData, ssoUserData, expiredSessionData } = props;

    this.searchDataStore.init(searchData, ssoUserData);
    this.ssoStore.init(ssoUserData, expiredSessionData);

    searchDataUrlSyncService.setupPushToRouterReaction();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    navigationService.updateQueryFromCurrentSearchData();
    this.disposeSavePrefillingOnBpm =
      this.searchDataReadStore.savePrefillingOnBpmReaction();
  }

  componentWillUnmount() {
    this.disposeSavePrefillingOnBpm?.();
  }

  async onClickNext() {
    const isValid = await this.onboardingValidationStore.validatePersonalDataOnboardingInputs();

    if (isValid) {
      piwikService.trackFormAnalyticsForElement(document.getElementById('onboardingPersonalDataPage'));
      await Router.push(navigationService.getOnboardingCoverageLink());
    }
  }

  _renderConsultantCarousel() {
    return (
      <div className="OnboardingPersonalData-ConsultantCheckpoints">
        <ConsultantInformationCarousel useConsultantImage />
      </div>
    );
  }

  render() {
    return (
      <Page dataTestId="onboardingPersonalDataPage" generalTrackingPage={GeneralTrackingPages.ONBOARDING_PERSONAL_DATA}>
        {endOfYearBannerService.showEndOfYearBanner() &&
          <Mobile.SavingsCampaignBanner
            countdown={endOfYearBannerService.calculateCampaignDuration()}
            lastDay={endOfYearBannerService.getLastDayOfDeadline()}
          />}

        <OnboardingProgressBar activeStep={1} />

        <div className="OnboardingPersonalData-content"
          id="onboardingPersonalDataPage"
          data-test-id="onboardingPersonalDataSection"
        >
          <OnboardingPersonalDataSection/>
          <OnboardingCta
            onClick={() => this.onClickNext()}
            label="weiter"
            dataTestId="OnboardingPersonalDataNextButton"
          />
          <div className="OnboardingPersonalData-campaignBanner">
            <CampaignBanner campaign={this.props.campaign} />
          </div>
          {this._renderConsultantCarousel()}
          <InsuranceLink />
        </div>
        <TrustLogos />
      </Page>
    );
  }
}

